<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    @beforeOpen="(event) => processParams(event.ref.params._rawValue)"
  >
    <!-- Begin::Header -->
    <div class="modal__header">
      <span class="modal__title">
        <slot name="title"></slot>
      </span>
    </div>
    <!-- End::Header -->
    <!-- Begin::Body -->
    <div class="modal__content">
      <!-- Begin::Details section -->
      <form @submit.prevent="redirectPatient" ref="form">
        <!-- Create two columns: patient's infos on the left, list of clinics on the right -->
        <div class="row">
          <!-- Patient's infos on the left -->
          <div v-if="patient" class="col-md-6">
            <div
              class="patient-details-container"
              style="
                background-color: #f9f9f9;
                padding: 16px 12px;
                border-radius: 8px;
                margin-bottom: 16px;
              "
            >
              <div class="d-flex flex-row align-items-start mb-3">
                <h5 class="fw-bolder mb-0 me-2">Prospect summary</h5>
                <router-link class="d-flex" :to="'/prospects/' + patient.id">See all</router-link>
              </div>
              <div class="row mb-8">
                <div class="col-md-12">
                  <label class="form-label">Full name</label>
                  <p>{{ patient.firstname }} {{ patient.lastname }}</p>
                </div>
                <div class="col-md-12">
                  <label class="form-label">Email</label>
                  <p>{{ patient.email }}</p>
                </div>
                <div class="col-md-6">
                  <label class="form-label">Location</label>
                  <p v-if="patient.address.city">
                    {{ patient.address.city }} ({{ patient.address.country }})
                  </p>
                  <p v-else>{{ patient.address.country }}</p>
                </div>
                <div class="col-md-6">
                  <label class="form-label">Language</label>
                  <p>{{ patient.language.toUpperCase() }}</p>
                </div>
              </div>

              <div class="d-flex flex-row align-items-start mb-3">
                <h5 class="fw-bolder mb-0 me-2">Rejections</h5>
              </div>
              <div v-for="event in patientHistory" :key="event.id">
                <p
                  class="mb-1"
                  v-if="
                    (event.logType === 'AUTO_REJECT_PROSPECT_RATE_EXCEEDED' || 
                    event.logType === 'REJECT_PROSPECT') &&
                    event.clinic
                  "
                >
                  {{
                    clinics.find((clinic) => clinic.id === event.clinic).name ||
                    "N/A"
                  }}
                  on {{ new Date(event.createdAt).toLocaleDateString() }} <span style="color: #484848; font-style: italic;">({{ Math.floor((new Date() - new Date(event.createdAt)) / (1000 * 60 * 60 * 24)) }} days ago)</span>
                </p>
              </div>
            </div>
            <!-- <div class="patient-history-container" style="background-color: #f9f9f9; padding: 16px 12px; border-radius: 8px;">
              <h5 class="fw-bolder mb-6">Patient History</h5>
              <ul class="timeline">
                <li
                  v-for="event in patientHistory"
                  :key="event.id"
                  class="timeline-item"
                >
                  <div class="timeline-content">
                    <span class="timeline-date">{{
                      new Date(event.createdAt).toLocaleDateString()
                    }}</span>
                    —
                    <span class="timeline-event">{{ event.logType }}</span>
                    <span v-if="event.clinic">
                      — <strong>Clinic:</strong>
                      {{
                        clinics.find((clinic) => clinic.id === event.clinic)
                          .name || "N/A"
                      }}
                    </span>
                  </div>
                </li>
              </ul>
            </div> -->
          </div>
          <!-- List of clinics on the right -->
          <div class="col-md-6">
            <h5 class="fw-bolder mb-6">New clinic</h5>
            <div class="mb-3">
              <input
                type="text"
                v-model="searchQuery"
                class="form-control"
                placeholder="Search clinics by name"
                style="margin-bottom: 22px"
              />
              <div
                v-for="clinic in filteredClinics"
                :key="clinic.id"
                class="form-check clinic-card"
                :class="{ selected: newClinic === clinic.id }"
                @click="newClinic = clinic.id"
              >
                <div>
                  <h2>{{ clinic.name }}</h2>
                  <p>
                    <strong>Location:</strong>
                    {{
                      [
                        ...new Set(
                          clinic.addresses.map(
                            (address) => `${address.city} (${address.country})`
                          )
                        ),
                      ].join(", ")
                    }}
                  </p>
                  <p>
                    <strong>Languages:</strong>
                    {{
                      clinic.presentation.spokenLanguages
                        .join(", ")
                        .toUpperCase() || "N/C"
                    }}
                  </p>
                  <p v-if="clinicHasRejectedProspect(clinic.id)" style="color:red; font-style: italic;">Rejected prospect</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!-- End::Header -->
    <!-- Begin::Footer -->
    <div class="modal__action">
      <div class="mx-4 mt-4" style="color: #484848">
        <p v-if="!newClinic">Please select a clinic</p>
        <p v-else>Prospect will be redirected to {{getClinicName(newClinic)}}</p>
      </div>
      <!-- <a class="btn btn-lg btn-primary me-2" @click="$emit('confirm', patient)"
          >Confirm</a
        > -->
      <a class="btn btn-lg btn-primary me-2" @click.prevent="onClickConfirm"
        >Confirm</a
      >
      <a class="btn btn-lg btn-secondary" @click="$emit('cancel')">Cancel</a>
    </div>
    <!-- End::Header -->
    <button class="modal__close btn btn-icon btn-link" @click="close">
      <i class="bi bi-x-lg"></i>
    </button>
  </vue-final-modal>
</template>
  
  <script>
import _ from "lodash";

import ApiService from "@/core/services/ApiService";
import { useToast } from "vue-toastification";

export default {
  emits: ["confirm", "cancel"],
  data() {
    return {
      newClinic: null,
      clinics: [],
      filteredClinics: [],
      patient: null,
      patientHistory: [],
      searchQuery: "",
    };
  },
  watch: {
    searchQuery(newQuery) {
      console.log("searchQuery", newQuery);
      this.filteredClinics = this.clinics.filter((clinic) =>
        clinic.name.toLowerCase().includes(newQuery.toLowerCase())
      );
    },
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  methods: {
    onClickConfirm() {
      this.$refs.form.requestSubmit();
    },
    redirectPatient() {
      console.log(JSON.parse(JSON.stringify(this.patient)));
      if (!this.newClinic) {
        this.toast.error("Please select a clinic.");
        return;
      }

      const config = {
        responseToast: {
          text: "Prospect redirected successfully.",
        },
      };

      ApiService.post(
        `/patients/${this.patient.id}/redirect-to-clinic/${this.newClinic}`,
        null,
        config
      )
        .then(({ data }) => {
          console.log(data);
          this.$emit("confirm", data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    processParams(patientId) {
      this.newClinic = null;
      this.clinics = [];
      this.filteredClinics = [];
      this.patient = {};
      this.patientHistory = [];
      this.searchQuery = "";

      ApiService.get("/patients/", patientId)
        .then(({ data }) => {
          console.log(data);
          this.patient = data;
        })
        .catch((err) => {
          console.log(err);
        });

      ApiService.query("/clinics", { params: { limit: 200 } })
        .then(({ data: clinics }) => {
          console.log(clinics);
          this.clinics = clinics.results || [];
          this.filteredClinics = [...this.clinics];
        })
        .catch((err) => {
          // this.isTableLoading = false;
        });

      ApiService.query("/contact-question-logs", {
        params: {
          patient: patientId,
          sortBy: "createdAt:desc",
          limit: 100,
        },
      })
        .then(({ data }) => {
          this.patientHistory = data.results;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getRejectionClinics(history) {
      const rejectionClinics = [];
      history.forEach((entry) => {
        if (entry.logType === "AUTO_REJECT_PROSPECT_RATE_EXCEEDED" || entry.logType === "REJECT_PROSPECT") {
          rejectionClinics.push(entry.clinic);
        }
      });
      return rejectionClinics;
    },
    clinicHasRejectedProspect(id) {
      return this.getRejectionClinics(this.patientHistory).includes(id);
    },
    getClinicName(clinicId) {
    for (let clinic of this.clinics) {
      if (clinic.id === clinicId) {
        return clinic.name;
      }
    }
    return "N/A"; // or return a default value if no match is found
    }
  },
};
</script>

<style scoped>
.clinic-info-label {
  display: block;
}

.clinic-card {
  border: 2px solid #f9f9f9;
  padding: 16px;
  border-radius: 8px;
  background-color: #f9f9f9;
  width: 100%;
  cursor: pointer;
  margin-bottom: 16px;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.clinic-card:hover {
  border-color: #ccc;
}

.clinic-card.selected:hover {
  border-color: black;
}

.clinic-card.selected {
  border-color: black;
}

.clinic-card h2 {
  font-weight: bold;
  margin-bottom: 8px;
}

.clinic-card p {
  margin: 4px 0;
}
</style>