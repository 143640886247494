<template>
    <div>
      <RedirectPatient
        name="redirect-patient-modal"
        v-model="isRedirectPatientModalOpen"
        @cancel="closeRedirectPatientModal"
        @confirm="onPatientRedirected"
      >
        <template v-slot:title>Redirect prospect</template>
      </RedirectPatient>
      <!--begin::Table-->
      <vue-good-table
        mode="remote"
        :total-rows="totalRecords"
        :is-loading="isTableLoading"
        :pagination-options="{ enabled: true }"
        :sort-options="{ enabled: false, initialSortBy: serverParams.sort }"
        :rows="rows"
        :columns="columns"
        :fixed-header="true"
        @page-change="onPageChange"
        @sort-change="onSortChange"
        @column-filter="onColumnFilter"
        @per-page-change="onPerPageChange"
        @row-click="openRedirectPatient"
        styleClass="custom-table clickable"
      >
        <template #loading-content>Loading...</template>
        <template #emptystate>No entry found</template>
        <template #table-row="props">
          <!-- Column: Action -->
          <span v-if="props.column.field === 'status'">
            <span class="badge" :class="`badge-light-${prospectStatusToColorClass(props.row.status)}`" :title="'Rejected by ' + props.row.clinic.name">
              Rejected by 
              <span v-if="props.row.status == 'HOT_PROSPECT_REJECTED_BY_CLINIC'">
                <span
                  v-if="props.row.clinic"
                >
                  {{ props.row.clinic.name.length > 20 ? props.row.clinic.name.substring(0, 17) + '...' : props.row.clinic.name }}
                </span>
              </span>
            </span>
          </span>
  
          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
  
        <!-- pagination -->
        <template #pagination-bottom="props">
          <div
            class="d-flex justify-content-between flex-wrap align-items-center"
          >
            <div class="d-flex align-items-center mb-0 mt-1">
              <select
                class="form-control form-control-sm me-3"
                :value="serverParams.perPage"
                @change="
                  (e) => props.perPageChanged({ currentPerPage: e.target.value })
                "
              >
                <option
                  v-for="(option, idx) in ['5', '10', '25', '50', '100']"
                  :key="`select-perpage-${idx}`"
                  :value="option"
                >
                  {{ option }}
                </option>
              </select>
              <span class="text-nowrap">prospects per page </span>
            </div>
            <div>
              <ul class="pagination">
                <li
                  class="page-item previous"
                  :class="{ disabled: serverParams.page === 1 }"
                >
                  <a
                    @click.prevent="
                      props.pageChanged({ currentPage: serverParams.page - 1 })
                    "
                    href="#"
                    class="page-link"
                    ><i class="previous"></i
                  ></a>
                </li>
                <div
                  v-for="idx in Math.ceil(props.total / serverParams.perPage)"
                  class="d-flex flex-row align-items-center"
                  :key="idx"
                >
                  <li
                    class="page-item"
                    :class="{ active: serverParams.page === idx }"
                  >
                    <a
                      @click.prevent="props.pageChanged({ currentPage: idx })"
                      href="#"
                      class="page-link"
                      v-if="
                        idx <= 3 ||
                        (idx >= serverParams.page - 1 &&
                          idx <= serverParams.page + 1) ||
                        idx >= Math.ceil(props.total / serverParams.perPage) - 2
                      "
                      >{{ idx }}</a
                    >
                  </li>
                  <p
                    class="mb-0 mt-1 mx-3"
                    v-if="
                      (idx === serverParams.page - 2 && idx > 3) ||
                      (idx === serverParams.page + 2 &&
                        idx < Math.ceil(props.total / serverParams.perPage) - 2)
                    "
                  >
                    ...
                  </p>
                </div>
                <li
                  class="page-item next"
                  :class="{
                    disabled:
                      serverParams.page ===
                      Math.ceil(props.total / serverParams.perPage),
                  }"
                >
                  <a
                    @click.prevent="
                      props.pageChanged({ currentPage: serverParams.page + 1 })
                    "
                    href="#"
                    class="page-link"
                    ><i class="next"></i
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </template>
      </vue-good-table>
      <!--end::Table-->
    </div>
  </template>
  
  <script>
  import ApiService from "@/core/services/ApiService";
  import RedirectPatient from "@/components/modals/patients/RedirectPatient.vue";
  import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
  import PatientStatus from "@/core/constants/PatientStatus";
  
  export default {
    name: "ProspectsManager",
    components: {
      RedirectPatient,
    },
    data() {
      return {
        isRedirectPatientModalOpen: false,
        isTableLoading: false,
        totalRecords: 0,
        columns: [
          
        ],
        rows: [],
        serverParams: {
          columnFilters: {},
          sort: {
            field: "updatedAt",
            type: "desc",
          },
          page: 1,
          perPage: 25,
        },
      };
    },
    mounted() {
        this.columns = [
          {
            label: "First name",
            field: "firstname",
            sortable: true,
          },
          {
            label: "Last name",
            field: "lastname",
            sortable: true,
          },
          {
            label: "Email",
            field: "email",
            sortable: true,
          },
          {
            label: "Country",
            field: "address.country",
            sortable: true,
          },
          // {
          //   label: "Requests",
          //   field: "contactQuestionLogs",
          //   formatFn: e => e.length,
          // },
          {
            label: "Last request",
            field: "request",
          },
          {
            label: "Status",
            field: "status",
          },
        ];
      
      this.fetchItemsFromApi();
      setCurrentPageBreadcrumbs("Prospects Redirection", []);
  
    },
    methods: {
      updateParams(newProps) {
        this.serverParams = { ...this.serverParams, ...newProps };
      },
      onPageChange(params) {
        console.log("onPageChange", params);
        this.updateParams({ page: params.currentPage });
        this.fetchItemsFromApi();
      },
      onPerPageChange(params) {
        this.updateParams({ perPage: params.currentPerPage });
        this.onPageChange({ currentPage: 1 });
      },
      onSortChange([params]) {
        this.updateParams({
          sort: {
            field: params.field,
            type: params.type,
          },
        });
        this.onPageChange({ currentPage: 1 });
      },
      onColumnFilter(params) {
        this.updateParams(params);
        this.onPageChange({ currentPage: 1 });
      },
      /**
       * Fetch models from API and display them in the table
       */
      fetchItemsFromApi() {
        this.isTableLoading = true;
  
        const tableSearch = {};
        console.log(this.serverParams.columnFilters);
        Object.keys(this.serverParams.columnFilters).forEach((e) => {
          if (!this.serverParams.columnFilters[e]) return;
          if (['status', 'language', 'id'].includes(e)) {
            tableSearch[e] = this.serverParams.columnFilters[e];
          } else {
            tableSearch[`${e}_contains`] = this.serverParams.columnFilters[e];
          }
        });
  
        const getApiParams = {
          ...tableSearch,
          status: "HOT_PROSPECT_REJECTED_BY_CLINIC",
          limit: this.serverParams.perPage,
          page: this.serverParams.page,
          sortBy: `${this.serverParams.sort.field}:${this.serverParams.sort.type}`,
          populate: 'contactQuestionLogs clinic',
        };
  
        ApiService.query("/patients", { params: getApiParams })
          .then((res) => {
            this.rows = res.data.results;
            this.totalRecords = res.data.totalResults;
            this.isTableLoading = false;
          })
          .catch((err) => {
            this.isTableLoading = false;
          });
      },
      openRedirectPatient(selectedPatient) {
        this.$vfm.show("redirect-patient-modal", selectedPatient.row.id);
      },
      closeRedirectPatientModal() {
        this.isRedirectPatientModalOpen = false;
      },
      onPatientRedirected() {
        this.$vfm.hide("redirect-patient-modal");
        this.fetchItemsFromApi();
      },
      prospectStatusToStr(status) {
        return PatientStatus[status].label;
      },
      prospectStatusToColorClass(status) {
        return PatientStatus[status].colorClass;
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .operations-table {
    background-color: #fff;
  }
  </style>